import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/core";

const ProductReviewsQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductReviews extends ReactComponent{

    constructor(props) {
        super(props);
        this.state = {
          isLoaded: false
        };
      }
    

    render(){
        var productIdValue = this.props.productId !== "" ? this.props.productId.toLowerCase(): "";
        return(
            <Query query={ProductReviewsQuery} variables={{productId: productIdValue, indexname: this.indexName}}>
              {({ loading, error, data}) => {
                          if (loading)
                              return(
                                  <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                                      <DotLoader
                                          css={override}
                                          sizeUnit={"px"}
                                          size={50}
                                          color={"#d6b25d"}
                                          loading={!this.state.isLoaded}
                                      />
                                  </div>
                              );
                          if (error) 
                          return (
                              <div>Error: {error.message}</div>
                          );
                          
                          return (
                            <>
                            {data && data.ratingcount.results &&  
                            data.ratingcount.results.totalCount !== parseInt(0) &&
                                <span>({data.ratingcount.results.totalCount} avis) </span>
                            }
                            </>
                          );
              
              }}

            </Query>
        );
        
        

    }

}
export default withSitecoreContext()(ProductReviews);
