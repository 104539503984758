import React from "react";
import {
  Placeholder,
  VisitorIdentification,
} from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import Tagging from "./components/Tagging";

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route, location }) => {
  let wrapperClass = "wide-wrap";
  let paddingClass = "no-padding ";
  let headingContainerClass = "heading-container";
  for (var component1 of route.placeholders["jss-main"]) {
    if (
      component1.placeholders !== undefined &&
      component1.componentName === "Post-LayoutWrapper"
    ) {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
    }
    if (
      component1.placeholders !== undefined &&
      component1.componentName === "ProductFinder-LayoutWrapper"
    ) {
      wrapperClass = "wide-wrap page-layout-left-sidebar page-layout-border";
    }
    if (
      component1.placeholders !== undefined &&
      component1.componentName === "ProductLayoutWrapper"
    ) {
      wrapperClass = "wide-wrap page-layout-right-sidebar page-layout-border";
      paddingClass = " ";
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {" "}
          {(route.fields &&
            route.fields.pageTitle &&
            route.fields.pageTitle.value) ||
            "Page"}{" "}
        </title>{" "}
        <meta
          name="description"
          content={
            route.fields &&
            route.fields.metaDescription &&
            route.fields.metaDescription.value
          }
        ></meta>
        <link
          rel="canonical"
          href={
            typeof window !== "undefined"
              ? window.location.href.toLowerCase()
              : ""
          }
        />
      </Helmet>
      <VisitorIdentification />
      <div id="wrapper" className={wrapperClass}>
        <header className="main-hd">
          <Placeholder name="jss-header" rendering={route} />{" "}
        </header>
        <div className={headingContainerClass}>
          <Placeholder name="jss-heading" rendering={route} />{" "}
        </div>{" "}
        <div
          className={
            "content-container " +
            paddingClass +
            ((route.fields &&
              route.fields.pageStyle &&
              route.fields.pageStyle.fields.title &&
              route.fields.pageStyle.fields.title.value) ||
              "")
          }
        >
          <Placeholder name="jss-main" rendering={route} />{" "}
        </div>{" "}
        <Placeholder name="jss-footer" rendering={route} />{" "}
        <Tagging pageTitle={route.fields.pageTitle.value} location={location}/>
      </div>
    </React.Fragment>
  );
};

export default Layout;
