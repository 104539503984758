import React from "react";
import { Text, Image, RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import "./FeaturedPosts.scss";
import LogoImg from "../../assets/StampJD.png";

const FeaturedPosts = (props) => {
  const { FeaturedPostsContentList, designVariant } = props.fields;
  return (
    <div className="featurePostsCustom">
      <div className="dessange__logoContainer">
        <div className="logoContainer">
          <img src={LogoImg} className="bestRatedLogo"></img>
        </div>
        <div className="separ"></div>
        <Text
          className="logoContainer__sectionTitle"
          field={props.fields.BestRatedProductsHeading}
          tag="h2"
        />
        <h2 className="push__sectionTitle">Nos conseils</h2>
      </div>

      <div className="featuredPostsCustomContainer">
        {FeaturedPostsContentList &&
          FeaturedPostsContentList.map((listItem, index) => (
            <div className="home-gamme__content-wrapper" key={index}>
              
              <div className="home-gamme__inner">
                
                <Image
                  field={listItem.fields.Icon}
                />
                <Text
                  field={listItem.fields.title}
                  tag="h3"
                  className="text-white titleGam"
                />
              <div className="desc-cn">
                  <RichText field={listItem.fields.content} />
                </div>
              </div>
              <a
                href={getRelativePaths(listItem.fields.PostLink.value.href)}
                className="btn btn_oro"
              >
                {listItem.fields.PostLink.value.text}
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FeaturedPosts;
