import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import ProductReviews from "../../utils/ProductReviews/index.js";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import i18next from 'i18next';
import "./ProductGridItem.scss";
import { productClickTagging, handleClickToBuy } from "../Tagging"; 

// PROPS :
// - product (graphql product item, required)
// - listView (boolean, optional)
class ProductGridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      isModalOpen: false,
    };
    this.openLightBox = this.openLightBox.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openLightBox(photoIndex) {
    this.setState({
      photoIndex: photoIndex,
      isOpen: true,
    });
  }

  openModal() {
    this.setState({
      isModalOpen: true,
    });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const product = this.props.product;
    const columnsize = this.props.columnsize;
    var images = [];
    if (product.item.image1) images.push(product.item.image1.src);
    if (product.item.image2) images.push(product.item.image2.src);
    if (product.item.image3) images.push(product.item.image3.src);
    if (product.item.image4) images.push(product.item.image4.src);

    return (
      <div
        className={
          product.item.natural.value === "1"
            ? "product-container isHoverGreen productGridItem productTag"
            : "product-container isHoverRed productGridItem productTag"
        }
        data-gtm-name={product.item.title.value}
        data-gtm-ean={product.item.codeEan.value}
        data-gtm-category={product.item.url.split('/')[2]}
        data-gtm-rating={product.item.rating.numberValue}
        data-gtm-list="list-result-range"
        data-gtm-position={this.props.index + 1}
        onClick={() =>
          productClickTagging(
            product.item.title.value,
            product.item.codeEan?.value,
            product.item.url.split('/')[2],
            product.item.rating?.numberValue,
            'list-result-range',
            this.props.index + 1
          )
        }
      >
        {this.state.isOpen && (
          <Lightbox
            mainSrc={images[this.state.photoIndex]}
            nextSrc={images[(this.state.photoIndex + 1) % images.length]}
            prevSrc={
              images[
                (this.state.photoIndex + images.length - 1) % images.length
              ]
            }
            onCloseRequest={() =>
              this.setState({
                isOpen: false,
                photoIndex: 0,
              })
            }
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % images.length,
              })
            }
          >
            test
          </Lightbox>
        )}
        {this.state.isModalOpen && (
          <Lightbox
            mainSrc={<RichText field={product.item.longDescription} />}
            onCloseRequest={() =>
              this.setState({
                isModalOpen: false,
              })
            }
          ></Lightbox>
        )}
        <figure>
          <NavLinkAdv to={getRelativePaths(product.item.url)}>
            <img
              src={product.item.image1.src}
              alt={product.item.image1.alt}
              style={{ height: 240, resize: "contain" }}
            />
          </NavLinkAdv>

          <figcaption>
            <NavLinkAdv
              to={getRelativePaths(product.item.url)}
              className="productTitleLink"
            >
              <h3 className={"product_title"}>{product.item.title.value}</h3>
            </NavLinkAdv>
            <p className="productTitleLinkBenefits">
              {product.item.beneficeProduit &&
                product.item.beneficeProduit.value}
            </p>
            {product.item.rating.numberValue != null &&
              product.item.rating.numberValue != 0 && (
                <div className="info-meta clearfix">
                  <StarRating value={product.item.rating.numberValue} />
                  <ProductReviews productId={product.item.id} />
                </div>
              )}
            {this.props.listView && (
              <>
                <div className="info-excerpt">
                  {product.item.shortDescription.value}
                </div>
              </>
            )}
          </figcaption>
        </figure>
        {product.item.codeEan ? (
          <button
            onClick={() => handleClickToBuy(product.item.title.value, product.item.codeEan.value)}
            data-eans={product.item.codeEan.value}
            className="swn-tag-wtb-btn product_item_buyButton"
          >
            {i18next.t('FindProduct')}
          </button>
        ) : (
          <NavLinkAdv
            to={getRelativePaths(product.item.url)}
            className={
              product.item.natural.value === "1"
                ? "btn_custom_oro isGreenButton"
                : "btn btn_custom_oro isRedButton"
            }
          >
            {i18next.t('FindProduct')}
          </NavLinkAdv>
        )}
      </div>
    );
  }
}

export default ProductGridItem;
