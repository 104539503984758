import React from "react";
import { Text, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import "./contact.scss";

const ContactForm = (props) => (
  <div className={"contactForm"}>
    <Text field={props.fields.contactUsTitle} tag={"h1"} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <iframe
      id="WebformLOreal"
      width="${width}"
      height="${height}"
      frameborder="0"
      scrolling="no"
      src="https://preprod-loreal-
consumer1.cs102.force.com/Webform/?Brand=Dessange&Country=France"
    ></iframe>{" "}
  </div>
);

export default withSitecoreContext()(ContactForm);
