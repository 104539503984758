import React from "react";
import {
  Text,
  Image,
  Link,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import "./Footer.scss";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  margin: auto;
`;

const FooterQuery = gqlLoader("./query.graphql");

// Fix to use NavLinkAdv or Link when needed
function LinkListRendered(props) {
  if (props.item.fields.InternalLink.value.linktype === "internal") {
    return (
      <NavLinkAdv
        to={props.item.fields.InternalLink.value.href}
        className="helv_lt"
      >
        <Text field={props.item.fields.Title} />
      </NavLinkAdv>
    );
  } else if (props.item.fields.InternalLink.value.linktype === "javascript") {
    let jsValue = props.item.fields.InternalLink.value.href.replace(
      ";return false;",
      ";"
    );
    let classValue = props.item.fields.InternalLink.value.class;
    return (
      <a id="ot-sdk-btn" className={`optanon-toggle-display helv_lt ${classValue}`}>
        <Text field={props.item.fields.Title} />
      </a>
    );
  } else {
    return (
      <Link field={props.item.fields.InternalLink} className="helv_lt">
        {props.item.fields.UseImageDisplay &&
          props.item.fields.UseImageDisplay.value != true && (
            <Text field={props.item.fields.Title} />
          )}
        {props.item.fields.UseImageDisplay &&
          props.item.fields.UseImageDisplay.value == true && (
            <img
              src={
                props.item.fields.ImageIcon &&
                props.item.fields.ImageIcon.value.src
              }
            />
          )}
      </Link>
    );
  }
}

class Footer extends ReactComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     itemsForRootpath: [],
  //     isLoaded: false,
  //   };
  // }

  // updateDataLayerTitle() {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({
  //     virtualPageTitle: document.title,
  //   });
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   Object.entries(this.props).forEach(([key, val]) => {
  //     if (prevProps[key] !== val) {
  //       // console.log(`Prop '${key}' changed`);
  //       // this.updateDataLayerTitle()
  //     }
  //   });
  // }

  render() {
    const { SocialLinks, designVariant } = this.props.fields;
    const { MenuLinks } = this.props.fields;

    return (
      <footer className={`main-ft ${designVariant && designVariant.value}`}>
        <div className="container">
          <div className="footer__inner">
            <div className="content">
              <div>
                <Query
                  query={FooterQuery}
                  variables={{
                    rootItem: "/sitecore/content/" + this.appName + "/home",
                  }}
                >
                  {({ loading, error, data }) => {
                     if (loading)
                       return (
                         <div
                           className="sweet-loading"
                           style={{ marginTop: "50px", marginBottom: "50px" }}
                         >
                           {/* <DotLoader
                             css={override}
                             sizeUnit={"px"}
                             size={50}
                             color={"#d6b25d"}
                             loading={!this.state.isLoaded}
                           /> */}
                         </div>
                       );

                    if (error) return <div>Error: {error.message}</div>;
                    let homeUrl = data && data.item && data.item.url;
                    return (
                      <NavLinkAdv to={homeUrl}>
                        <img
                          className="logo"
                          src={
                            this.props.fields.Logo &&
                            this.props.fields.Logo.value.src
                          }
                        />
                      </NavLinkAdv>
                    );
                  }}
                </Query>
              </div>
              <div className="link_social mt-0">
                {SocialLinks &&
                  SocialLinks.map((listItem, index) => (
                    <Link
                      field={listItem.fields.InternalLink}
                      target="_blank"
                      key={index}
                    >
                      <img
                        src={
                          listItem.fields.Image &&
                          listItem.fields.Image.value.src
                        }
                      />
                    </Link>
                  ))}
              </div>
            </div>
            <div className="footer__text">
              <div className="mt-0">
                <Text
                  className="link_stext"
                  field={this.props.fields.Text}
                  tag="strong"
                />
              </div>
              <div className="link_contact">
                {MenuLinks.map((listItem, index) => (
                  <LinkListRendered key={index} index={index} item={listItem} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-ft">
          <div className="footer__remarks">
            <div>
              <span className="helv_rom">
                <Text field={this.props.fields.FooterRemarks} />
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default withSitecoreContext()(Footer);
