import React from 'react';
import { Text, RichText, withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/core';
import { DotLoader } from 'react-spinners';
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";
import './timeline.scss';

const TimelineQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class Timeline extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  render() {
    function SortByYear(x,y) {
      if (y.item.eventYear && y.item.eventYear.numberValue !== undefined) {
        return ((x.item.eventYear.numberValue === y.item.eventYear.numberValue) ? 0 : ((x.item.eventYear.numberValue > y.item.eventYear.numberValue) ? 1 : -1 ));
      }
    }

    return (
        <>
        <Query query={TimelineQuery} variables={{
          rootpath: '/sitecore/content/' + this.appName + '/',
          indexname: this.indexName
        }}>
          {({loading, error, data}) => {
            if (loading)
              return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#d6b25d'}
                        loading={!this.state.isLoaded}
                    />
                  </div>
              );
            if (error)
              return (
                  <div>Error: {error.message}</div>
              );
            else if (data.search.results.items.length === 0)
              return (
                  isExperienceEditorActive() && (
                      <div>{i18next.t("NoTimelineFoundLabel")}</div>
                  )
              );
            return (
                <React.Fragment>
                    <div className="history" id="history">
                        <div className="col-md-12 pad timelineTi">
                            <svg className="vectorDashes" preserveAspectRatio="xMinYMin meet" viewBox="0 0 211 197">
                                <path d="M55,0 l65,0 l-55,250 l-65,0 z"></path>
                                <path d="M146,0 l65,0 l-55,250 l-65,0 z"></path>
                            </svg>
                            <Text field={this.props.fields && this.props.fields.Title} tag={"h2"}/>
                        </div>
                      {
                        data.search.results &&
                        data.search.results.items.sort(SortByYear).map(function (event, idx) {
                          if (idx % 2 === 0) {
                            return (
                                <div className="row_custom_small changeOrder" key={idx}>
                                  <div className="col-md-5 c-l type-txt">
                                    <div><h2><Text field={event.item.eventTitle}/></h2>
                                      <RichText field={event.item.eventContent}/>
                                    </div>
                                  </div>
                                  <div className="col-md-7 c-r type-num">
                                    <p>
                                        <Text field={event.item && event.item.eventYear}/>
                                    </p>
                                  </div>
                                </div>
                            )
                          } else {
                            return (
                                <div className="row_custom_small" key={idx}>
                                  <div className="col-md-7 c-l type-num">
                                    <p>
                                        <Text field={event.item.eventYear}/>
                                    </p>
                                  </div>
                                  <div className="col-md-5 c-r type-txt">
                                    <div><h2><Text field={event.item.eventTitle}/></h2>
                                      <RichText field={event.item.eventContent}/>
                                    </div>
                                  </div>
                                </div>
                            )
                          }
                        })
                      }
                    </div>
                </React.Fragment>
            )
          }}
        </Query>
        </>
    )
  }
}

export default withSitecoreContext()(Timeline);
