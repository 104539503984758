import React from "react";
import { Text, Image, Link } from "@sitecore-jss/sitecore-jss-react";
import { getRelativePaths } from "../../utils/Utils.js";
import { HashLink as HLink } from "react-router-hash-link";
import "./PushItem.scss";
class PushItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackgroundCover: { display: "none" },
    };
  }

  componentDidMount() {
    if (this.props !== null) {
      if (this.props.fields.BackgroundImage !== null) {
        this.setState({
          showBackgroundCover: {
            backgroundImage:
              "url(" + this.props.fields.BackgroundImage.value.src + ")",
          },
        });
      }
    }
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        behavior: "smooth",
      });
    };
    return (
      <HLink
        to={getRelativePaths(this.props.fields.PushItemLink.value.href + "#" + this.props.fields.PushItemLink.value.anchor)}
        scroll={(el) => scrollWithOffset(el, 5)}
        className={designVariant + " linkItem"}
      >
        <img
          src={this.props.fields.BackgroundImage.value.src}
          className="linkItem__img"
        />
        <div className="decoration"></div>
      </HLink>
    );
  }
}

export default PushItem;
