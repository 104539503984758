import React from "react";
import {
  Placeholder,
  Text,
  withPlaceholder,
} from "@sitecore-jss/sitecore-jss-react";
import "./ListOfPush.scss";
import LogoImg from "../../assets/StampJD.png";

const ListOfPush = (props) => {
  return (
    <React.Fragment>
      <div className="dessange__logoContainer">
        <div className="logoContainer">
          <img src={LogoImg} className="bestRatedLogo"></img>
        </div>
        <div className="separ"></div>
        <Text
          className="logoContainer__sectionTitle"
          field={props.fields.BestRatedProductsHeading}
          tag="h2"
        />
        <h2 className="push__sectionTitle">La Marque</h2>
      </div>

      <div className="centerBlock listOfPush_container">
        <Placeholder
          name="push-items-placeholder"
          rendering={props.rendering}
        />
      </div>
    </React.Fragment>
  );
};

export default withPlaceholder("push-items-placeholder")(ListOfPush);
