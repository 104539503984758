import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../lib/GraphQLData";
import ProductReviews from "../../utils/ProductReviews/index.js";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import i18next from 'i18next';
import "./BestRatedProducts.scss";
import LogoImg from "../../assets/StampJD.png";
import { productClickTagging } from "../Tagging";

const BestRatedProductsQuery = gqlLoader("./BestRatedProductsQuery.graphql");

const BestRatedProducts = (props) => {
  const designVariant =
    props.fields.designVariant === undefined
      ? ""
      : props.fields.designVariant && props.fields.designVariant.value;
  const graphQLResult = props.bestRatedProductsQuery;
  const { datasource } = graphQLResult;
  
  return (
    <>
      <div className="dessange__logoContainer">
        <div className="logoContainer">
          <img src={LogoImg} className="bestRatedLogo"></img>
        </div>
        <div className="separ"></div>
        <Text
          className="logoContainer__sectionTitle"
          field={props.fields.BestRatedProductsHeading}
          tag="h2"
        />
      </div>


      <div className="bestrelated__product__custom home-bestsellers">
        {datasource &&
          datasource.bestRatedProductsList.targetItems.map(
            (productItem, index) => {
              return(
                <div className="pad productTag product-container" key={index}
                data-gtm-name={productItem.title.jss.value}
                data-gtm-ean={productItem.codeEan.value}
                data-gtm-category={productItem.parent.title.value}
                data-gtm-rating={productItem.rating.numberValue}
                data-gtm-list="cross-sell"
                data-gtm-position={index + 1}
                onClick={() => productClickTagging(productItem.title.jss.value, productItem.codeEan.value, productItem.parent.title.value, productItem.rating.numberValue,
                  'cross-sell',
                  index + 1)}
                > 
                <div
                  className={
                    productItem.natural.value === "1"
                      ? "portfolio-item text-center isHoverGreen"
                      : "portfolio-item text-center isHoverRed"
                  }
                  key={index}
                >
                  <NavLinkAdv
                    className="portfolio-link"
                    to={getRelativePaths(productItem.url)}
                    
                  >
                    <img
                      src={productItem.image1 && productItem.image1.src}
                      className="img-fluid"
                    />

                    <div className="portfolio-caption min_font">
                      <Text field={productItem.title.jss} tag="h3" />
                      <Text field={productItem.beneficeProduit.jss} tag={"p"} />
                      <div className="portfolio-rating">
                        {productItem.rating.numberValue > 0 && 
                          <span>
                            <StarRating value={productItem.rating.numberValue} />
                            <ProductReviews productId={productItem.id} />
                          </span>
                        }
                      </div>
                    </div>
                  </NavLinkAdv>
                </div>
                <NavLinkAdv
                  to={productItem.url}
                  onClick={() => productClickTagging(productItem.title.jss.value, productItem.codeEan.value, productItem.parent.title.value,productItem.rating.numberValue,
                    'cross-sell',
                    index + 1)}
                  className={
                    productItem.natural.value === "1"
                      ? "btn btn_custom_oro isGreenButton"
                      : "btn btn_custom_oro isRedButton"
                  }
                >
                  {i18next.t("product-view-product")}
                </NavLinkAdv>
              </div>
              )
              
            }
          )}
      </div>
      {/* </div> */}
    </>
  );
};

export default GraphQLData(BestRatedProductsQuery, {
  name: "bestRatedProductsQuery",
})(BestRatedProducts);
