import React from "react";
import {
  RichText,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import QueryBuilder from "../../utils/QueryBuilder.js";
import i18next from 'i18next';
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import "./GlobalSearch.scss";
import { withRouter } from "react-router";
import ReactComponent from "../../ReactComponent";
import { productClickTagging, handleInternalSearch } from "../Tagging/index.js";

class GlobalSearch extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      itemdata: [],
      tempKeyword: "",
    };
    this.onItemsChange = this.onItemsChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onItemsChange(itemdata) {
    this.setState({ itemdata: itemdata });
  }

  componentDidMount() {
    const url = window.location.href;
    let keyword = "";
    if (url.includes("?search=")) {
      keyword = url.substring(url.indexOf("=") + 1);

      if (keyword.includes("#")) keyword = keyword.replace("#", "");
      this.setState({
        keyword: decodeURIComponent(keyword),
        tempKeyword: decodeURIComponent(keyword),
      });

     //GTM
     handleInternalSearch(decodeURIComponent(keyword))
    }
  }

  handleClick() {
    this.setState({
      keyword: this.state.tempKeyword,
    });
  }

  handleClose() {
    this.setState({
      tempKeyword: "",
    });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      tempKeyword: e.target.value,

    });


  }

  handleSubmit(e) {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.set("search", this.state.tempKeyword);
    this.props.history.push(
      this.props.location.pathname + "?" + params.toString()
    );
    this.setState({
      keyword: this.state.tempKeyword,
    });

    //GTM
    handleInternalSearch(this.state.tempKeyword);
  }

  render() {
    let SITE_ROOTPATH = "/sitecore/content/" + this.appName;
    let QUERY =
      'query{ search: customSearch( index:"' +
      this.indexName +
      '", rootItem: "' +
      SITE_ROOTPATH +
      '", conditions: [ {name: "_templatename", value: "PostRoute", useor:true}, {name: "_templatename", value: "ProductRoute", useor:true}], keyword: "##keyword") { results { totalCount pageInfo { hasNextPage startCursor endCursor hasPreviousPage } items { path templateName item { id url name ... on PostRoute { postTitle { value } postThumbnailImageMedium { src alt } postShortDescription { value } } ... on ProductRoute { codeEan { value } title { value } image1 { src alt } image2 { src alt } rating { numberValue } productShortDescription { value } } parent { ... on CategoryRoute { title { value } } } date: field(name: "__Updated") { value } } } } } } ';

    const { keyword, itemdata } = this.state;

    let empty = null;
    if (itemdata.data && itemdata.data.search.results.items.length === 0) {
      empty = (
        <div>
          Nous n'avons trouvé aucun produit correspondant à votre recherche
        </div>
      );
    }
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return (
      <div className={`${designVariant}`}>
        <div className="heading-container">
          <div
            className="heading-background bg-2"
            style={{
              backgroundImage:
                `url(` + this.props.fields.backgroundImage.value.src + `)`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading-wrap">
                    <div className="page-title mb-3">
                      <Text tag="h1" field={this.props.fields.title} />
                    </div>
                    <div className="form-wrapper">
                      <form className="searchBar" onSubmit={this.handleSubmit}>
                        <div className="search-wrapper">
                          <input
                            type="search"
                            placeholder="VOTRE RECHERCHE..."
                            value={this.state.tempKeyword}
                            onChange={this.handleChange}
                          />
                          <input
                            type="submit"
                            id="globalSearchSubmit"
                            className="hidden"
                            name="submit"
                            value="Search"
                          ></input>
                          <i
                            className="fa fa-search"
                            onClick={this.handleClick}
                          ></i>
                          <i
                            onClick={this.handleClose}
                            className={
                              this.state.tempKeyword === ""
                                ? "hidden"
                                : "fa fa-close"
                            }
                          ></i>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container search-results">
          <div className="row">
            <div className="main-wrap">
              <div className="search-grid">
                {itemdata.data && empty && empty}
                {itemdata.data &&
                  itemdata.data.search.results.items &&
                  itemdata.data.search.results.items.map((result, index) => {
                    return (
                      <div 
                      data-gtm-name={result.item.title.value}
                      data-gtm-ean={result.item.codeEan.value}
                      data-gtm-category={result.item.parent.title.value}
                      data-gtm-rating={result.item.rating.numberValue}
                      data-gtm-list="list-result-search"
                      data-gtm-position={index + 1}
                      key={index} className="search__productContainer product-container">
                        <NavLinkAdv 
                        onClick={() => productClickTagging(result.item.title.value, result.item.codeEan.value, result.item.parent.title.value, 0, "list-result-search", index+1)}
                        to={getRelativePaths(result.item.url)}
                        className=""
                        >
                        <div className="search__productContainer__img">
                            <img
                              src={result.item.image1.src}
                              alt={result.item.image1.alt}
                            />
                        </div>
                        <div className="search__productContainer__description">
                          <span
                            className="search__productContainer__description__title"
                          >
                            {result.item.postTitle
                              ? result.item.postTitle.value
                              : result.item.title.value}
                          </span>

                          <span
                            className="search__productContainer__description__button"
                          >
                            {i18next.t("product-view-product")}
                          </span>
                        </div>
                        </NavLinkAdv>
                      </div>
                    );
                    return false;
                  })}
                <QueryBuilder
                  onItemsChange={this.onItemsChange}
                  query={QUERY}
                  itemsPerPage={this.props.fields.itemsPerPage.value}
                  keyword={keyword}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(withRouter(GlobalSearch));
