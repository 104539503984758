import React from "react";
import { Text, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { css } from "@emotion/core";
import { DotLoader } from "react-spinners";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import "./articleCategoriesNav.scss";
import NavLinkAdv from "../../utils/NavLinkAdv";

const ArticleCategoriesNavigationQuery = gqlLoader("./query.graphql");
const override = css`
  margin: auto;
`;

class ArticleCategoriesNavigation extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <div className="cat__custom__article row">
        <Query
          query={ArticleCategoriesNavigationQuery}
          variables={{
            indexname: this.indexName,
            rootpath: "/sitecore/content/" + this.appName + "/home",
          }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div
                  className="sweet-loading"
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                >
                  <DotLoader
                    css={override}
                    sizeUnit={"px"}
                    size={50}
                    color={"#d6b25d"}
                    loading={!this.state.isLoaded}
                  />
                </div>
              );
            if (error) return <div>Error: {error.message}</div>;
            return (
              data &&
              data.product.results.items.map(function(category, idx) {
                let showBackgroundCover = { display: "none" };
                if (category.item !== null) {
                  showBackgroundCover = {
                    backgroundImage:
                      "url("+
                      category.item.articleImage.src +
                      ")",
                  };
                }
                return (
                  <div
                    style={showBackgroundCover}
                    className="item col-md-4 col-sm-12"
                    key={idx}
                  >
                    {category.item !== null &&
                      (category.item.url.startsWith("http") === true ? (
                        <a href={category.item && category.item.url}>
                          <Text
                            tag="span"
                            field={category.item && category.item.articleTitle}
                            className="helv_md"
                          />
                        </a>
                      ) : (
                        <NavLinkAdv
                          to={category.item && category.item.url}
                          title=""
                        >
                          <Text
                            tag="span"
                            field={category.item && category.item.articleTitle}
                            className="helv_md"
                          />
                        </NavLinkAdv>
                      ))}
                  </div>
                );
              })
            );
          }}
        </Query>
      </div>
    );
  }
}

export default withSitecoreContext()(ArticleCategoriesNavigation);
