import React from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
import "./HeadingBanner.scss";
const HeadingBanner = (props) => {
  return (
    <div
      className={"heading-background bg-2 "} // + designVariant.value
      style={{
        backgroundImage: `url(` + `${props.fields.backgroundImage.value.src})`,
      }}
    ></div>
  );
};

export default HeadingBanner;
