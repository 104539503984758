import React from "react";

const StarRating = ({ value }) => {
  let rating = [];
  var integerPart = parseInt(value);
  var decimalPart = (value - integerPart) * 10;
  var index = 0;

  // Full Star
  for (var x = 1; x <= integerPart; x++) {
    rating.push(<i className="fa fa-star" key={index} />);
    index++;
  }

  // Half Star
  if (decimalPart > 0 && decimalPart < 6) {
    rating.push(<i className="fa fa-star-half-o" key={decimalPart} />);
    integerPart += 1;
    index++;
  } else if (decimalPart > 5 && decimalPart < 10) {
    rating.push(<i className="fa fa-star" key={decimalPart} />);
    integerPart += 1;
    index++;
  }

  // Remaining Star
  for (var x = 1; x <= 5 - integerPart; x++) {
    rating.push(<i className="fa fa-star-o" key={index} />);
    index++;
  }

  return <div>{rating}</div>;
};

export default StarRating;
