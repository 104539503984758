import React from "react";
import {
  Text,
  Image,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { css } from "@emotion/core";
import { DotLoader } from "react-spinners";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import NavLinkAdv from "../../utils/NavLinkAdv";
import "./articleCategories.scss";
import LogoImg from "../../assets/StampJD.png";

const ArticlesNavigationQuery = gqlLoader("./query.graphql");
const override = css`
  margin: auto;
`;

class ArticlesNavigation extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  render() {
    return (
      <div className="articlesNavigation">
        <div className="dessange__logoContainer">
          <div className="logoContainer">
            <img src={LogoImg} className="bestRatedLogo"></img>
          </div>
          <div className="separ"></div>
          <h1 className="logoContainer__sectionTitle">
            {this.props.fields && this.props.fields.Heading.value}
          </h1>
        </div>

        <div className="container">
        <div className="articlesNavigation__container">
          <Query
            query={ArticlesNavigationQuery}
            variables={{
              indexname: this.indexName,
              categoryId: this.props.sitecoreContext.route.itemId
                .split("-")
                .join("")
                .toLowerCase(),
            }}
          >
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div
                    className="sweet-loading"
                    style={{ marginTop: "50px", marginBottom: "50px" }}
                  >
                    <DotLoader
                      css={override}
                      sizeUnit={"px"}
                      size={50}
                      color={"#d6b25d"}
                      loading={!this.state.isLoaded}
                    />
                  </div>
                );
              if (error) return <div>Error: {error.message}</div>;

              return data.product.results.items.map(function(article, idx) {
                return (
                  <React.Fragment key={idx}>
                    {article &&
                    article.item &&
                    article.item.url &&
                    article.item.url.startsWith("http") === true ? (
                      <a
                        href={article.item && article.item.url}
                        className="col-md-4 item__article__only"
                      >
                        <img
                          src={
                            article.item &&
                            article.item.articleImage &&
                            article.item.articleImage.src
                          }
                          className="img_custom_full"
                        />
                        {/*<small><Moment className="time_article" format="MMM DD, YYYY">{article.item && article.item.date.value}</Moment></small>*/}
                        <Text
                          field={article.item && article.item.articleTitle}
                          tag="h1"
                        />
                        <button className="btn btn-oro">
                          {i18next.t("SeeMoreButton")}
                        </button>
                      </a>
                    ) : (
                      <div className="articleCategories__article">
                        {console.log(article.item.url)}
                        <NavLinkAdv
                          to={article.item && article.item.url}
                          className="link"
                        >
                          <img
                            src={
                              article.item &&
                              article.item.articleImage &&
                              article.item.articleImage.src
                            }
                            className="articleCategories__article__img"
                          />
                          <Text
                            field={article.item && article.item.articleTitle}
                            tag="h1"
                            className="articleCategories__article__title"
                          />
                          <button className="articleCategories__article__button">
                            {i18next.t("SeeMoreButton")}
                          </button>
                        </NavLinkAdv>
                      </div>
                    )}
                  </React.Fragment>
                );
              });
            }}
          </Query>
        </div>
        </div>
        
      </div>
    );
  }
}

export default withSitecoreContext()(ArticlesNavigation);
