import React from "react";
import QueryBuilder from "../../utils/QueryBuilder.js";
import ProductGridItem from "../ProductGridItem/index.js";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import "./FilteredProductList.scss";
import ReactComponent from "../../ReactComponent";

class FilteredProductList extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemdata: [],
      filters: [],
      view: "list",
      itemsPerPage: "12",
      sorting: ["__Sortorder", "ASC"],
      keyword: "",
      init: true,
      categId: this.props.sitecoreContext.itemId
    };
    this.onItemsChange = this.onItemsChange.bind(this);
  }

  componentDidMount() {
    const url = window.location.href;

    var filters = [];

    let sortByRanking = ["globalRanking", "ASC"];
    if (
      this.props.fields.SortByGlobalRanking &&
      this.props.fields.SortByGlobalRanking.value == "1"
    ) {
      this.setState({
        sorting: sortByRanking,
      });
    }
  }

  onItemsChange(itemdata) {

    this.setState({
      itemdata: itemdata,
    });
    if (itemdata && itemdata.data)
      this.props.onFacetsChange(itemdata.data.searchFacets.facets);
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevProps.sitecoreContext.itemId !== this.props.sitecoreContext.itemId){
      this.setState({categId: this.props.sitecoreContext.itemId})
    }

    if (prevProps.categoriesSession !== this.props.categoriesSession) {
      
      let filters = [];

      for (var filter of this.props.categoriesSession) {
        if (filter.toLowerCase() !== "") {
          filters.push(
            '{name: "categoryRange", value: "' + filter.toLowerCase() + '"},'
          );
        }
      }
      this.setState({
        filters: filters,

        init: true,
      });
    }
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    function SortByBackofficeSortOrder(x, y) {
      return parseInt(x.item.SortValue.value) ===
        parseInt(y.item.SortValue.value)
        ? 0
        : parseInt(x.item.SortValue.value) > parseInt(y.item.SortValue.value)
        ? 1
        : -1;
    }

    var INDEX = this.indexName;
    let QUERY =
      'query={ searchFacets:customSearch( index: "' +
      INDEX +
      '", conditions: [{name: "_templatename", value: "ProductRoute"}, ##fieldsEqual ], keysearch: "##keyword",  facetOn: ["categoryRange"], rootItem: "' +
      this.state.categId.replace(/-/g, "").toLowerCase() +
      '") { ' +
      "facets { name values { count value " +
      'item { id ... on ProductCategoryTemplate { title { value } } } } } } search:customSearch( index: "' +
      INDEX +
      '", conditions: [{name: "_templatename", value: "ProductRoute"}, ##fieldsEqual ], keysearch: "##keyword",  ##sortBy  rootItem: "' +
      this.state.categId.replace(/-/g, "").toLowerCase() +
      '") { results { totalCount pageInfo { startCursor endCursor hasNextPage hasPreviousPage } items { item { id url name ... on ProductRoute { SortValue: field(name:"__Sortorder"){ value } title { value } natural{value} shortDescription: productShortDescription { value } longDescription:productLongDescription { value } image1 { src value alt } image2 { src value alt } image3 { src value alt } image4 { src value alt } codeEan { value }  rating { numberValue } beneficeProduit{ value } ' +
      'parent { ... on CategoryRoute { title { value}}}' +
      "  categoryRange  { targetItems { id ... on ProductCategoryTemplate { title { value } } } }   } } } } } }";

    let itemdataSorted =
      this.props.fields.SortByGlobalRanking &&
      this.props.fields.SortByGlobalRanking.value == "1"
        ? this.state.itemdata &&
          this.state.itemdata.data &&
          this.state.itemdata.data.search.results.items
        : this.state.itemdata &&
          this.state.itemdata.data &&
          this.state.itemdata.data.search.results.items.sort(
            SortByBackofficeSortOrder
          );

    return (
      <>
        <div className={`container mt-5 ${designVariant}`}>
          <div className="list__product__item">
            {this.state.itemdata &&
            this.state.itemdata.data &&
            itemdataSorted.length > 0 ? (
              itemdataSorted.map((product, index) => (
                <ProductGridItem
                  key={index}
                  index={index}
                  product={product}
                  listView={false}
                  columnsize={
                    this.props.fields.GridItemsPerRow &&
                    this.props.fields.GridItemsPerRow.value
                  }
                />
              ))
            ) : (
              <p className={"col-md-12 text-center"}>
                Aucun résultat ne correspond à votre recherche.
              </p>
            )}
          </div>
          <nav className="commerce-pagination col-md-12">
            {this.state.init && (
              <QueryBuilder
                onItemsChange={this.onItemsChange}
                query={QUERY}
                showIndexCount={true}
                solrFields={this.state.filters}
                itemsPerPage={this.state.itemsPerPage}
                sort={this.state.sorting}
                keyword={this.props.keyword}
              />
            )}
          </nav>
        </div>
      </>
    );
  }
}

export default withSitecoreContext()(FilteredProductList);
