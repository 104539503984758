import React from "react";
import packageJson from "../../../package.json";
import SCJSS from "../../temp/config";

// Todo Adrien assure décommenter avant de push sur le git
class Context {
  // static getIndexName(args) {
  //   // return (
  //   //   "sitecore_" +
  //   //   args.sitecoreContext.route.databaseName +
  //   //   "_" +
  //   //   packageJson.config.appName.split("-").join("_") +
  //   //   "_index"
  //   // );
  //   return "sitecore_master_index";
  // }

  static getIndexName(args) {
    return (
      "sitecore_" +
      args.sitecoreContext.route.databaseName +
      "_" +
      packageJson.config.appName.split("-").join("_") +
      "_index"
    );
  }

  static getUrlSer() {
    return SCJSS.sitecoreApiHost;
  }
}

export default Context;
