import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselHome.scss";

class CarouselHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      activeDot: 0,
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot,
    });
  }

  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    };

    const slidesList = this.props.fields.Slides;

    let dots = [];
    for (let i = 0; i <= slidesList.length; i++) {
      dots.push(
        <div
          className={"owl-dot" + this.state.activeDot === i ? "selected" : ""}
          key={i}
          onClick={() => this.setActiveDot(i)}
        ></div>
      );
    }

    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;

    return (
      <div className={"homeCarouselWrapper"}>
        <div className="container-fluid">
          <div className="row homeCarousel" style={{ maxHeight: "561px" }}>
            <Slider {...settings} style={{ maxHeight: "561px" }}>
              {slidesList &&
                slidesList.map((listItem, index) => (
                  <li key={`listItem-${index}`} style={{ maxHeight: "561px" }}>
                    {listItem.fields.Banner.value.src ? (
                      <div
                        className="imgContainer"
                        style={{
                          backgroundImage:
                            `url(` + listItem.fields.Banner.value.src + `)`,
                        }}
                      >
                        {listItem.fields.Subtitle.value !== "" && (
                          <div className="wrapperCenter">
                            <div className="tp-caption business_small_white tp-resizeme subtitle">
                              <Text field={listItem.fields.Subtitle} />
                            </div>
                          </div>
                        )}
                        {listItem.fields.Title.value !== "" && (
                          <div className="wrapperCenter">
                            <div className="tp-caption business_big_white tp-resizeme title">
                              <RichText field={listItem.fields.Title} />
                            </div>
                          </div>
                        )}
                        <div className="wrapperCenter">
                          {listItem.fields.ButtonLeft.value !== "" && (
                            <div className="btnWrapper">
                              <a
                                href={
                                  listItem.fields.ButtonLeft &&
                                  listItem.fields.ButtonLeft.value.href
                                }
                                className=""
                              >
                                {listItem.fields.ButtonLeft.value.text
                                  ? listItem.fields.ButtonLeft.value.text
                                  : "Lorem Ipsum"}
                              </a>
                            </div>
                          )}
                          {/* {listItem.fields.ButtonRight.value !== "" && (
                            <div className="btnWrapper">
                              <a
                                href={
                                  listItem.fields.ButtonRight &&
                                  listItem.fields.ButtonRight.value.href
                                }
                                className="btn btn-white-outline"
                              >
                                {listItem.fields.ButtonRight.value.text
                                  ? listItem.fields.ButtonRight.value.text
                                  : "Lorem Ipsum"}
                              </a>
                            </div>
                          )} */}
                        </div>
                        {/*}*/}
                      </div>
                    ) : (
                      <div>
                        {listItem.fields.LinkVideo.value.href &&
                          listItem.fields.LinkVideo.value.linktype ===
                            "external" && (
                            <iframe
                              title="youtube"
                              width="1920"
                              height="505"
                              src={listItem.fields.LinkVideo.value.url.replace(
                                "https://youtu.be/",
                                "https://www.youtube.com/embed/"
                              )}
                            />
                          )}
                        {listItem.fields.LinkVideo.value.href &&
                          listItem.fields.LinkVideo.value.linktype ===
                            "media" && (
                            <video
                              style={{ width: "100%", height: "561px" }}
                              className="video-fluid"
                              loop
                              autoPlay
                              muted
                            >
                              <source
                                src={listItem.fields.LinkVideo.value.href}
                                type="video/mp4"
                              />
                            </video>
                          )}
                        {listItem.fields.LinkVideo.value.linktype !==
                          "external" && (
                          <div>
                            {listItem.fields.Subtitle.value !== "" && (
                              <div className="wrapperCenter">
                                <div className="tp-caption business_small_white tp-resizeme subtitle">
                                  <Text field={listItem.fields.Subtitle} />
                                </div>
                              </div>
                            )}

                            <div className="button-wrapper">
                              {listItem.fields.ButtonLeft &&
                                listItem.fields.ButtonLeft.value.href !==
                                  "" && (
                                  <div className="button-wrapper__link-container">
                                    <a
                                      className="btn"
                                      href={getRelativePaths(
                                        listItem.fields.ButtonLeft.value.href
                                      )}
                                    >
                                      {listItem.fields.ButtonLeft.value.text}
                                    </a>
                                  </div>
                                )}
                              {listItem.fields.ButtonRight &&
                                listItem.fields.ButtonRight.value.href !==
                                  "" && (
                                  <div>
                                    <a
                                      className="carousel__btn"
                                      href={getRelativePaths(
                                        listItem.fields.ButtonRight.value.href
                                      )}
                                    >
                                      {listItem.fields.ButtonRight.value.text}
                                    </a>
                                  </div>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselHome;
