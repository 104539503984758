import React from 'react';
import context from "./utils/Context";
import packageJson from '../package.json';
import configFile from '../src/temp/config';
class ReactComponent extends React.Component{

    indexName;
    appName;
    graphQLEndpoint;
    constructor(props){
        super(props);
        this.indexName = context.getIndexName(props);
        this.appName = packageJson.config.appName;
        this.graphQLEndpoint = configFile.graphQLEndpoint;
        this.urlServer = context.getUrlSer();
    }

    getUrlEmbeded(url) {
        if (url !== ""){
            let cleanUrl = this.checkIsLanguageExist(url);

            if (this.props.sitecoreContext.site.isLanguageEmbedded === true)
                return "/" + this.props.sitecoreContext.language + cleanUrl;
            else
                return cleanUrl;
        }else{
            return "/";
        }
    }

    checkIsLanguageExist(url) {
        if (this.props.to !== undefined && url.includes("/"+ this.props.sitecoreContext.language +"/"))
            return url.substr(this.props.sitecoreContext.language.length + 1);
        else
            return url;
    }
}

export default ReactComponent;
