import React from "react";
import {
  Placeholder,
  Text,
  withSitecoreContext,
  Image,
} from "@sitecore-jss/sitecore-jss-react";
import "./articleLayoutWrapper.scss";

const ArticleLayoutWrapper = (props) => {
  return (
    <div className="articleLayoutWrapper">
      <Text
        field={props.sitecoreContext.route.fields.articleTitle}
        tag="h1"
        className="articleLayoutWrapper__title"
      />
      <Image
        field={props.sitecoreContext.route.fields.articleImage}
        className="articleLayoutWrapper__img"
      />

      <div className="row_custom_small row-article-layout">
        <div className="col-md-8 articleLayoutWrapper__description">
          <Placeholder
            name="article-main-content"
            rendering={props.rendering}
            className="articleLayoutWrapper__description"
          />
        </div>
        <div className="col-md-4">
          <Placeholder
            name="article-sidebar-right"
            rendering={props.rendering}
          />
        </div>
      </div>
    </div>
  );
};
export default withSitecoreContext()(ArticleLayoutWrapper);
