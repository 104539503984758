import React from "react";
import {
  RichText,
  Text,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import "./richTextComponent.scss";
import LogoImg from "../../assets/StampJD.png";
// import { useParams } from "react-router";

class RichTextComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return designVariant === "marque" ? (
      <div id={this.props.fields.blockAnchorID && this.props.fields.blockAnchorID.value} className="dessange__logoContainer">
        <div className="logoContainer">
          <img src={LogoImg} className="bestRatedLogo"></img>
        </div>
        <div className="separ"></div>
        {/* <Text
          className="logoContainer__sectionTitle"
          field={this.props.fields.Text}
          tag="h2"
        /> */}
        <div className="logoContainer__sectionTitle">
          {this.props.fields.Text.value.replace("<p>", "").replace("</p>", "")}
        </div>
        {/* {this.props.fields.Text.value} */}
      </div>
    ) : (
      <div
        className={`row ${designVariant}`}
        id={
          this.props.fields.blockAnchorID &&
          this.props.fields.blockAnchorID.value
        }
      >
        <div className="col-md-12 text-center all-articles__custom richtext_custom otherRich">
          <RichText
            field={this.props.fields.Title && this.props.fields.Title}
            tag={"h2"}
          />
          <RichText
            className="all-articles__content"
            field={this.props.fields.Text && this.props.fields.Text}
          />
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(RichTextComponent);
